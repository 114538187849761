import React from 'react';

import { SxProps, keyframes } from '@mui/system';
import type { Theme } from '@mui/material/styles';

import { Typography } from '@mui/material';

interface IProps {
	text: string;
}

const pop = keyframes({
	from: { transform: 'scale(0.875)' },
	to: { transform: 'scale(1)' }
})

const root: SxProps<Theme> = {
	position: 'relative',
	fontFamily: 'Minecraftia',
	fontWeight: 'normal',
	fontStyle: 'normal',
	left: '55%',
	top: '90%',
	color: 'yellow',
	rotate: '-20deg',
	animation: `${pop} 250ms alternate infinite`,
	textDecoration: 'none',
	pointerEvents: 'none',
	userSelect: 'none',
};

export const Splash: React.FunctionComponent<IProps> = ({ text }) => (
	<Typography sx={root} variant={"body1"}>{text}</Typography>
);

export default Splash;
