import React from 'react';

import type { SxProps } from '@mui/system';
import type { Theme } from '@mui/material/styles';

import { Box, Paper, Link } from '@mui/material';
import { Splash } from './Splash';

import getRandomBackground from './img/background';
import crafterd from './img/crafterd.jpg';

const background: SxProps<Theme> = {
	position: 'fixed',
	top: '50%',
	left: '50%',
	width: '110%',
	height: '110%',
	transform: 'translate(-50%, -50%)',
	backgroundColor: 'rgba(0, 0, 0, 0.85)',
	backgroundImage: `url(${getRandomBackground()})`,
	backgroundSize: 'cover',
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
	filter: 'blur(5px)',
	zIndex: -1,
};

const foreground: SxProps<Theme> = {
	height: '100vh',
	width: '100%',

	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'column',

	'& > *:not(:first-child)': { margin: '0.5rem' },
};

const logo: SxProps<Theme> = {
	width: '10rem',
	height: '10rem',
	backgroundImage: `url(${crafterd})`,
	backgroundSize: 'cover',
	transition: 'transform 250ms',
	imageRendering: 'crisp-edges',

	'&:hover': { transform: 'scale(1.05)' },
};

export const App: React.FunctionComponent = () => (
	<>
		<Box sx={background} />
		<Box sx={foreground}>
			<Link href={"https://github.com/crafterd"} underline={"none"}>
				<Paper sx={logo} elevation={12}>
					<Splash text={"Coming Soon!"} />
				</Paper>
			</Link>
		</Box>
	</>
);

export default App;
