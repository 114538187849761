import React from 'react';

import { ThemeProvider, CssBaseline, createTheme } from '@mui/material';
import type { Theme } from '@mui/material';

export const DefaultTheme = createTheme({
	palette: {
		background: {
			default: '#f8f8fa',
			paper: '#333547',
		},
		primary: {
			main: '#333547',
		},
	},
	typography: {
		h5: {
			color: 'rgba(255, 255, 255, 0.85)',
		},
	},
});

export const WithTheme = (theme: Theme, component: React.ReactNode) => (
	<ThemeProvider theme={theme}>
		<CssBaseline />
		{component}
	</ThemeProvider>
);

export default WithTheme;
