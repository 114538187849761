import background1 from './backgrounds/1.jpg';
import background2 from './backgrounds/2.jpg';
import background3 from './backgrounds/3.jpg';
import background4 from './backgrounds/4.jpg';
import background5 from './backgrounds/5.jpg';
import background6 from './backgrounds/6.jpg';

export const backgrounds = [
	background1,
	background2,
	background3,
	background4,
	background5,
	background6,
];

const getRandomBackground = () => {
	return backgrounds[Math.floor(Math.random()*backgrounds.length)];
}

export default getRandomBackground;
